import {
  faArrowTrendDown,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faBadgeCheck,
  faBars,
  faBell,
  faBuilding,
  faCalculatorSimple,
  faChartLine,
  faChartUser,
  faCheck,
  faClipboardListCheck,
  faClock,
  faCog,
  faFileExport,
  faFileImage,
  faGauge,
  faGreaterThan,
  faInfoCircle,
  faLessThan,
  faListOl,
  faLocationDot,
  faMoneyCheck,
  faObjectIntersect,
  faPlus,
  faSpinner,
  faTimes,
  faTimesCircle,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export const AppFAIcons = [
  faArrowUpRightFromSquare,
  faArrowTrendDown,
  faArrowTrendUp,
  faBadgeCheck,
  faBars,
  faBell,
  faBuilding,
  faCalculatorSimple,
  faChartLine,
  faChartUser,
  faCheck,
  faClipboardListCheck,
  faChevronDown,
  faClock,
  faCog,
  faFileExport,
  faFileImage,
  faGauge,
  faGreaterThan,
  faInfoCircle,
  faLessThan,
  faListOl,
  faLocationDot,
  faMoneyCheck,
  faListOl,
  faObjectIntersect,
  faPlus,
  faSpinner,
  faTimes,
  faTimesCircle,
  faUsers,
];
